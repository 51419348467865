import React from "react";
import Iframe from "react-iframe";
import { Link } from "gatsby";

export default function Contact() {
  return (
    <>
      <section id="contact">
        <div className="container-fluid">
          <div className="section-header">
            <h3>Get in Touch</h3>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="map mb-4 mb-lg-0">
                <iframe
                  frameborder="0"
                  style={{ height: "860px", width: "100%", border: "0px" }}
                  src="https://forms.zohopublic.com/xccelerata1/form/CONNECTWITHUS/formperma/4wQpWHAOIaE4toVWGL8sf6P5x8n_4nIvVemZBGi3B3I"
                ></iframe>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-4 info">
                  <i>
                    <img
                      className="img-icon2"
                      src="/assets/img/contact page icon/remcopy.png"
                      alt=""
                    />
                  </i>

                  <p className="contact-add">
                    {" "}
                    Suite 501, 218 Export Blvd,
                    <br /> Mississauga, ON L5S 0A7, <br /> Canada
                  </p>
                </div>
                <div className="col-md-4 info">
                  <i>
                    <img
                      className="img-icon3"
                      src="/assets/img/contact page icon/messagecopy 2.png"
                      alt=""
                    />
                  </i>
                  <p style={{ marginLeft: "3px" }}>contact@xccelerata.com</p>
                </div>
                <div className="col-md-4 info">
                  <i>
                    <img
                      className="img-icon2"
                      src="/assets/img/contact page icon/mobile-phone.png"
                      alt=""
                    />
                  </i>
                  <p>+1-833-207-2727</p>
                  {/* <i><img className="img-icon2" src="/assets/img/contact page icon/mobile-phone.png" alt="" /></i> */}
                  <p style={{ marginTop: "-35px" }}>+1-833-207-ASAP</p>
                </div>
              </div>
              <div className="row" style={{ marginTop: "30px" }}>
                <div className="col-md-12"  style={{ paddingTop: "10px",  backgroundColor: "#f5f5f5", textAlign: "center" }}>
                  <h4><b>Other Locations</b></h4>
                </div>
                <div className="col-md-4" style={{ color: "red", padding: "10px", border: "groove", textAlign: "center", borderWidth: "1px"}}>
                <i>
                    <img
                      className="img-icon2"
                      src="/assets/img/contact page icon/remcopy.png"
                      alt=""
                    />
                  </i>
                 <h5>USA</h5> 
                </div>
                <div className="col-md-4" style={{ color: "red", padding: "10px", border: "groove", textAlign: "center", borderWidth: "1px"}}>
                <i>
                    <img
                      className="img-icon2"
                      src="/assets/img/contact page icon/remcopy.png"
                      alt=""
                    />
                  </i>
                <h5>SWITZERLAND</h5>
                </div>
                <div className="col-md-4" style={{ color: "red", padding: "10px", border: "groove", textAlign: "center", borderWidth: "1px"}}>
                <i>
                    <img
                      className="img-icon2"
                      src="/assets/img/contact page icon/remcopy.png"
                      alt=""
                    />
                  </i>
                <h5>INDIA</h5>
                </div>
              </div>
              <div className="row" style={{ marginTop: "30px" }}>
                <div className="col-md-4 info"></div>

                <img
                  style={{ height: "280px" }}
                  src="/assets/img/contact-page-pic.png"
                  className="img-fluid"
                  alt=""
                />

                <div className="col-md-4 info"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
